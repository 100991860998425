import React from 'react'

export const Home = () => {
  return (
    <div>
        {/* <h3>This is a home page</h3> */}
      
    </div>
  )
}

export default Home
