import React from 'react'

const About = () => {
  return (
    <div>
      {/* <h3>This is an about tab</h3> */}
    </div>
  )
}

export default About
