import React from 'react'

export const User = () => {
  return (
    <div>
      {/* <h3>This is an user tab</h3> */}
    </div>
  )
}

export default User
